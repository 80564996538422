<template>
  <div class="stacks-page pa-0">
    <div class="stacks-page__content">
      <CyWizardServiceCatalog
        hide-use-btns
        hide-hidden-filter/>
    </div>
    <div class="stacks-page__sidebar">
      <div class="sidebar">
        <CyWizardStackInsightsCard
          class="sidebar__section"/>
        <div class="sidebar__section">
          <v-card
            outlined
            :to="{ name: 'formsEditor' }">
            <v-card-text class="card-wrapper">
              <CyAvatar
                class="card-wrapper__avatar"
                :item="{
                  icon: 'tune',
                  color: 'dev',
                }"/>
              <div class="card-wrapper__text">
                <div>
                  <h3 class="content__title">
                    {{ $t('stackFormsEditorTitle') }}
                  </h3>
                  <div
                    class="content__subtitle"
                    v-html="$sanitizeHtml($t('stackFormsEditorDescription'))"/>
                </div>
                <CyButton
                  class="content__button"
                  icon="chevron_right"
                  icon-only
                  variant="tertiary"
                  theme="primary"
                  sm/>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CyWizardServiceCatalog from '@/components/CyWizardServiceCatalog.vue'
import CyWizardStackInsightsCard from '@/components/CyWizardStackInsightsCard.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageStacks',
  components: {
    CyWizardServiceCatalog,
    CyWizardStackInsightsCard,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.stacks'), [
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.stacksSection'),
      description: {
        text: this.$t('routes.stacksSectionDescription'),
        link: this.$docLinks.stacks.overview,
      },
    }
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.stacks',
        stackFormsEditorDescription: 'Write and edit your StackForms .forms.yml file with live preview and validation.',
        stackFormsEditorTitle: 'StackForms editor',
      },
      es: {
        title: '@:routes.stacks',
        stackFormsEditorDescription: 'Escriba y edite su file StackForms .forms.yml con vista previa y validación en vivo.',
        stackFormsEditorTitle: 'Editor StackForms',
      },
      fr: {
        title: '@:routes.stacks',
        stackFormsEditorDescription: 'Créez et editez votre fichier .forms.yml StackForms avec un aperçu et une validation en temps réel.',
        stackFormsEditorTitle: 'Éditeur StackForms',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.stacks-page {
  display: grid !important;
  grid-template-areas:
    "content"
    "sidebar";
  width: 100%;
  margin-top: 0 !important;

  &__content {
    display: flex;
    grid-area: content;
    flex-direction: column;
    padding-top: $spacer-6;
    padding-bottom: 64px;

    @include breakpoint("xl") {
      padding: #{$spacer-6} #{$spacer-8} 64px 0;
    }
  }

  &__sidebar {
    grid-area: sidebar;

    @include breakpoint("xl") {
      padding: #{$spacer-6} #{$spacer-8};
      border-left: solid 1px cy-get-color("grey", "light-2");
    }
  }

  @include breakpoint("xl") {
    grid-template-areas: "content sidebar";
    grid-template-columns: minmax(0, 1fr) 464px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;

  &__section + &__section {
    margin-top: $spacer-6;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: $spacer;
    font-weight: bold;
  }

  a {
    &:hover,
    &:focus,
    &:visited:hover {
      color: cy-get-color("grey");
    }
  }
}

.card-wrapper {
    display: flex;
    gap: $spacer-4;

    &__avatar  {
      opacity: 0.8;
    }

    .cy-avatar__icon {
      font-size: 24px !important;
    }

    &__text {
      display: flex;
      gap: $spacer-4;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;

      .content__subtitle {
        color: cy-get-color("grey", "dark-2");
      }

      .content__button {
        align-self: center;
      }
    }
  }
</style>
